import React from 'react';

import Box from 'components/Box';
import InnerWrap from 'components/layout/InnerWrap';
import OverlayBox from 'components/OverlayBox';
import bricks from 'images/bricks.svg';

const MultiSectionBackgroundWrap: React.FC = ({ children }) => {
  return (
    <Box position="relative" py={[8, 9]} px={5} sx={{ overflow: 'hidden' }}>
      <OverlayBox
        sx={{
          backgroundImage: `url(${bricks})`,
          backgroundSize: '2000px',
          transform: 'rotate(-45deg)',
        }}
        height="200%"
        width="200%"
        top="-50%"
        left="-50%"
        opacity={0.2}
        zIndex={-1}
      />
      <InnerWrap
        maxWidth={1400}
        bg="white70"
        sx={{
          boxShadow: '0px 0px 30px 80px rgba(255,255,255,0.7)',
          '& > *': { py: [8, 9], '&:first-child': { pt: [0] }, '&:last-child': { pb: [0] } },
        }}
      >
        {children}
      </InnerWrap>
    </Box>
  );
};

export default MultiSectionBackgroundWrap;
