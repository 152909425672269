import React from 'react';

import { BoxProps } from 'components/Box';
import H1 from 'components/H1';
import InnerWrap from 'components/layout/InnerWrap';
import OuterWrap from 'components/layout/OuterWrap';
import OverlayBox from 'components/OverlayBox';

type Props = BoxProps & {
  title: string;
  pictureUrl: string;
  outerSx?: Record<string, unknown>;
  overlayProps?: BoxProps;
};

const PagePictureTitle: React.FC<Props> = ({
  title,
  pictureUrl,
  outerSx,
  overlayProps,
  children,
  ...props
}) => {
  return (
    <OuterWrap
      position="relative"
      py={[9, 11]}
      bg="grey700"
      color="white"
      sx={{
        backgroundImage: `url(${pictureUrl})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        ...outerSx,
      }}
      {...props}
    >
      <OverlayBox bg="trueBlack" opacity={0.4} {...overlayProps} />
      <InnerWrap position="relative" zIndex={1}>
        <H1 textAlign="center" color="white">
          {title}
        </H1>
        {children}
      </InnerWrap>
    </OuterWrap>
  );
};

export default PagePictureTitle;
