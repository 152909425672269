import React from 'react';

import { PracticeArea } from 'types/schema';
import Box from 'components/Box';
import Grid from 'components/Grid';
import PortableText from 'components/PortableText';

type Props = {
  practiceAreas: PracticeArea[];
};

const PracticeAreasSection: React.FC<Props> = ({ practiceAreas }) => {
  return (
    <Grid as="section" gridTemplateColumns={['1fr', '1fr 1fr']} gridGap={6} mt={7}>
      {practiceAreas.map(({ title, _rawBody: body, _key }) => (
        <Box
          key={_key}
          sx={{
            '&:last-child': { bg: 'grey900', color: 'white', py: 5, px: 6 },
            'p a': { textDecoration: 'underline' },
            'p + p': { mt: 3 },
          }}
        >
          {title && (
            <Box as="h2" mb={4}>
              {title}
            </Box>
          )}
          <PortableText blocks={body} />
        </Box>
      ))}
    </Grid>
  );
};

export default PracticeAreasSection;
