import { graphql } from 'gatsby';
import React from 'react';

import {
  BaseSanityPage,
  ExpertiseSectionData,
  PortableTextBlocks,
  PracticeArea,
} from 'types/schema';
import Box from 'components/Box';
import ExpertiseSection from 'components/layout/ExpertiseSection';
import MainLayout from 'components/layout/MainLayout';
import MultiSectionBackgroundWrap from 'components/layout/MultiSectionBackgroundWrap';
import PagePictureTitle from 'components/layout/PagePictureTitle';
import PracticeAreasSection from 'components/layout/PracticeAreasSection';
import PortableText from 'components/PortableText';
import variants from 'components/theme/variants';

export const pageQuery = graphql`
  query EmployeeLawPageQuery {
    sanityEmployeeLawPage(id: { eq: "-2abbc923-0431-52b1-9788-9c3222aaf27b" }) {
      id
      meta {
        title
        description
      }
      pageTitle
      _rawHeaderContent
      practiceAreas {
        title
        _rawBody
        _key
      }
      expertise {
        title
        body
        expertiseListTitle
        expertiseList
        contactCTACopy
        highlight1Title
        highlight1Body
        highlight2Title
        highlight2Body
        highlight3Title
        highlight3Body
      }
    }
  }
`;

type Props = {
  data: {
    sanityEmployeeLawPage: BaseSanityPage & {
      _rawHeaderContent: PortableTextBlocks;
      practiceAreas: PracticeArea[];
      expertise: ExpertiseSectionData;
    };
  };
};

const EmployeesPage: React.FC<Props> = ({
  data: {
    sanityEmployeeLawPage: {
      meta: { title, description },
      pageTitle,
      _rawHeaderContent,
      practiceAreas,
      expertise,
    },
  },
}) => {
  return (
    <MainLayout seoProps={{ title, description, pagePath: '/employment-law/employees/' }}>
      <PagePictureTitle
        title={pageTitle}
        pictureUrl="https://images.unsplash.com/photo-1561054182-e28182699d1b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1350&q=80"
        outerSx={{ backgroundPosition: 'center bottom' }}
        overlayProps={{ opacity: 0.5 }}
        pb={[7, 9]}
      >
        <Box
          mt={10}
          mx="auto"
          width="100%"
          maxWidth={800}
          bg="grey90090"
          color="white"
          py={7}
          px={8}
          sx={{ h2: { mb: 5, ...variants.header2Serif } }}
        >
          <PortableText blocks={_rawHeaderContent} />
        </Box>
      </PagePictureTitle>
      <MultiSectionBackgroundWrap>
        <PracticeAreasSection practiceAreas={practiceAreas} />
        <ExpertiseSection data={expertise} />
      </MultiSectionBackgroundWrap>
    </MainLayout>
  );
};

export default EmployeesPage;
