import React from 'react';

import { ExpertiseSectionData } from 'types/schema';
import Box from 'components/Box';
import Grid from 'components/Grid';
import LinkButton from 'components/LinkButton';

type Props = { data: ExpertiseSectionData };

const ExpertiseSection: React.FC<Props> = ({ data }) => {
  const {
    title,
    body,
    expertiseListTitle,
    expertiseList,
    contactCTACopy,
    highlight1Title,
    highlight1Body,
    highlight2Title,
    highlight2Body,
    highlight3Title,
    highlight3Body,
  } = data;
  return (
    <Box as="section">
      {!!title && (
        <Box as="h2" variant="header2Serif" mb={5}>
          {title}
        </Box>
      )}
      <Box as="p" maxWidth={1000}>
        {body}
      </Box>
      <Box mt={4}>{expertiseListTitle}</Box>
      <Grid as="ul" gridTemplateColumns={['1fr', '1fr 1fr']} gridColumnGap={40} mt={4}>
        {expertiseList.map((exp) => (
          <li key={exp}>{exp}</li>
        ))}
      </Grid>
      <LinkButton to="/contact/" bVariant="primary" display="inline-block" mt={5}>
        {contactCTACopy}
      </LinkButton>
      <Grid gridTemplateColumns={['1fr', '1fr 1fr 1fr']} gridGap={6} mt={6}>
        <Box>
          <Box as="h4" variant="header5Serif" color="darkGold" mb={4}>
            {highlight1Title}
          </Box>
          <p>{highlight1Body}</p>
        </Box>
        <Box>
          <Box as="h4" variant="header5Serif" color="darkGold" mb={4}>
            {highlight2Title}
          </Box>
          <p>{highlight2Body}</p>
        </Box>
        <Box>
          <Box as="h4" variant="header5Serif" color="darkGold" mb={4}>
            {highlight3Title}
          </Box>
          <p>{highlight3Body}</p>
        </Box>
      </Grid>
    </Box>
  );
};

export default ExpertiseSection;
